export const empresas = [
  {
    ID: 4,
    Nombre: "Pedregal",
  },
  {
    ID: 10,
    Nombre: "Saturno",
  },
];
