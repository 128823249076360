import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import drilldown from "highcharts/modules/drilldown";
// import { servicio } from "../data/Data";
import DarkUnica from "highcharts/themes/dark-unica";
DarkUnica(Highcharts);

const GraficoBarras = ({ coins, search }) => {
  const titleStyle = { textAlign: "center" };
  let title;

  // let arrAnhoMes = [
  //   "2023-01",
  //   "2023-02",
  //   "2023-03",
  //   "2023-04",
  //   "2023-05",
  //   "2023-06",
  //   "2023-07",
  //   "2023-08",
  //   "2023-09",
  //   "2023-10",
  //   "2023-11",
  //   "2023-12",
  // ];
  let arrAnhoMes = [];

  // const d = new Date(2023, -5, 1);
  const d = new Date();
  const Anho = d.getFullYear();
  const Mes = d.getMonth();

  // for(let i = 0; i <= 11; i++){
  for (let i = 11; i >= 0; i--) {
    const fechaAagregar = new Date(Anho, Mes - i, 1);
    let mesAagregar = fechaAagregar.getMonth() + 1;
    mesAagregar = mesAagregar < 10 ? "-0" + mesAagregar : "-" + mesAagregar;
    let anhoAagregar = fechaAagregar.getFullYear();
    const fechaAagregarText = anhoAagregar + mesAagregar;
    arrAnhoMes.push(fechaAagregarText);
  }

  let arregloEmpresaCantidad = [];
  let subtotal = 0;
  let servicio;

  if (search === "" || search === "Todas") {
    title = "Accesos de empresas por mes";
    servicio = coins;
    for (let i = 0; i < servicio.length; i++) {
      const emp = servicio[i].Empresa;
      const fund = servicio[i].Fundos;
      let cantidadMesXCantidad = [];
      for (let i = 0; i < fund.length; i++) {
        const ing = fund[i].Ingresos;
        for (let i = 0; i < arrAnhoMes.length; i++) {
          const anhoMes = arrAnhoMes[i];
          subtotal = 0;
          for (let i = 0; i < ing.length; i++) {
            let result = ing[i].Fecha.substring(0, 7);
            if (anhoMes === result) {
              subtotal = subtotal + ing[i].Cantidad;
            }
          }
          if (cantidadMesXCantidad.length >= 12) {
            cantidadMesXCantidad[i] = cantidadMesXCantidad[i] + subtotal;
          } else {
            cantidadMesXCantidad.push(subtotal);
          }
          // cantidadMesXCantidad[i] = cantidadMesXCantidad[i]?cantidadMesXCantidad[i]:0 + subtotal
        }
      }
      // arregloEmpresaCantidad.push({ Empresa: emp, Cantidad: subtotal });
      arregloEmpresaCantidad.push({
        name: `${emp}`,
        data: `${cantidadMesXCantidad}`,
      });
    }
  } else {
    title = `Accesos de fundos de ${search} por mes`;
    servicio = coins.filter((e) => e.Empresa === search);
    for (let i = 0; i < servicio.length; i++) {
      // const emp = servicio[i].Empresa;
      const fund = servicio[i].Fundos;

      for (let i = 0; i < fund.length; i++) {
        const ing = fund[i].Ingresos;
        let cantidadMesXCantidad = [];
        for (let i = 0; i < arrAnhoMes.length; i++) {
          const anhoMes = arrAnhoMes[i];
          subtotal = 0;
          for (let i = 0; i < ing.length; i++) {
            let result = ing[i].Fecha.substring(0, 7);
            if (anhoMes === result) {
              subtotal = subtotal + ing[i].Cantidad;
            }
          }
          if (cantidadMesXCantidad.length >= 12) {
            cantidadMesXCantidad[i] = cantidadMesXCantidad[i] + subtotal;
          } else {
            cantidadMesXCantidad.push(subtotal);
          }
          // cantidadMesXCantidad[i] = cantidadMesXCantidad[i]?cantidadMesXCantidad[i]:0 + subtotal
        }
        arregloEmpresaCantidad.push({
          name: `${fund[i].Fundo}`,
          data: `${cantidadMesXCantidad}`,
        });
      }
      // arregloEmpresaCantidad.push({ Empresa: emp, Cantidad: subtotal });
    }
  }

  for (let i = 0; i < arregloEmpresaCantidad.length; i++) {
    const currentData = arregloEmpresaCantidad[i].data;
    arregloEmpresaCantidad[i].data = currentData.split(",");
  }
  for (let i = 0; i < arregloEmpresaCantidad.length; i++) {
    let currentData2 = arregloEmpresaCantidad[i].data;
    for (let i = 0; i < currentData2.length; i++) {
      currentData2[i] = Number(currentData2[i]);
    }
  }

  // const dummy = [
  //   {
  //     name: "ACP",
  //     data: [49, 71, 106, 129, 144, 176, 135, 148, 216, 194, 95, 54],
  //   },
  //   {
  //     name: "Agrovision",
  //     data: [83, 78, 98, 93, 106, 84, 105, 104, 91, 83, 106, 92],
  //   },
  //   {
  //     name: "Safco",
  //     data: [48, 38, 39, 41, 47, 48, 59, 59, 52, 65, 59, 51],
  //   },
  //   {
  //     name: "Pedregal",
  //     data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51],
  //   },
  //   {
  //     name: "Pampa baja",
  //     data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51],
  //   },
  //   {
  //     name: "Agricola Chapi",
  //     data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51],
  //   },
  //   {
  //     name: "Manuelita",
  //     data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51],
  //   },
  // ];

  // const options = {
  //   title: {
  //     text: "My chart",
  //   },
  //   series: [{ name: "Profit", data: [1, 2, 3] }],
  // };

  // drilldown(Highcharts);

  // const options = {
  //   chart: {
  //     type: "column",
  //   },
  //   title: {
  //     text: "Accesos por empresa por mes",
  //   },
  //   xAxis: {
  //     type: "category",
  //   },
  //   yAxis: {
  //     title: {
  //       text: "Cantidad de modelos por Marca",
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Meses",
  //       colorByPoint: true,
  //       data: [
  //         {
  //           name: "Samsung",
  //           y: 5, //Cantidad de modelos
  //           drilldown: "samsung",
  //         },
  //         {
  //           name: "Xiaomi",
  //           y: 6, //Cantidad de modelos
  //           drilldown: "xiaomi",
  //         },
  //         {
  //           name: "Motorola",
  //           y: 4, //Cantidad de modelos
  //           drilldown: "motorola",
  //         },
  //       ],
  //     },
  //   ],
  //   drilldown: {
  //     series: [
  //       {
  //         id: "samsung",
  //         data: [
  //           ["Samsung Galaxy S10", 4],
  //           ["Samsung Galaxy S10+", 2],
  //           ["Samsung Note 9", 1],
  //           ["Samsung J7 Prime", 2],
  //           ["Samsung A8", 1],
  //         ],
  //       },
  //       {
  //         id: "xiaomi",
  //         data: [
  //           ["Xiaomi Mi 8", 4],
  //           ["Xiaomi Mi 8 Lite", 6],
  //           ["Xiaomi Redmi Note 7", 7],
  //           ["Xiaomi Redmi Note 6 pro", 1],
  //           ["Xiaomi Mi 9", 10],
  //           ["Xiaomi Mi A2", 5],
  //         ],
  //       },
  //       {
  //         id: "motorola",
  //         data: [
  //           ["Motorola G7 plus", 4],
  //           ["Motorola G6", 2],
  //           ["Motorola One", 3],
  //           ["Motorola Z3 play", 6],
  //         ],
  //       },
  //     ],
  //   },
  // };

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      // categories: [
      //   "2023 Enero",
      //   "2023 Febrero",
      //   "2023 Marzo",
      //   "2023 Abril",
      //   "2023 Mayo",
      //   "2023 Junio",
      //   "2023 Julio",
      //   "2023 Agosto",
      //   "2023 Setiembre",
      //   "2023 Octubre",
      //   "2023 Noviembre",
      //   "2023 Diciembre",
      // ],
      categories: arrAnhoMes,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "N° visitas",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        // '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: arregloEmpresaCantidad,
    // series : dummy,
  };

  return (
    <div id="GraficoBarras">
      <h3 style={titleStyle}>{title}</h3>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default GraficoBarras;
