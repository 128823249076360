import { useEffect, useState } from "react";
import { Title } from "./components/Titulo";
// import { Fechas } from "./components/Fechas";
// import SelectCultivos from "./components/SelectCultivos";
// import SelectsAnidados from "./components/SelectsAnidados";
import GraficoBarras from "./components/GraficoBarras";
import GraficoCircular from "./components/GraficoCircular";
import { empresas as data } from "./data/Empresas";

import "./App.css";

function App() {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");

  const getData = async () => {
    const options = {
      IDApp: 1,
    };
    const params = {
      method: "POST",
      body: JSON.stringify(options),
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };
    const API_URL = "https://indices.api.agrosoftweb.com";
    const api = `${API_URL}/metricas`;
    const request = new Request(api, params);
    const response = await fetch(request);
    const res = await response.json();

    setCoins(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const [empresas, setEmpresas] = useState([]);
  useEffect(() => {
    setEmpresas(data);
  }, []);

  const titleStyle = { marginRight: "20px" };

  return (
    <div className="container">
      <Title title="Métricas de índices de vegetación" />
      {/* <Fechas /> */}
      {/* <SelectEmpresas /> */}
      <label style={titleStyle}>Empresas</label>
      <select onChange={(e) => setSearch(e.target.value)}>
        <option value="Todas" key="0">
          Todas
        </option>
        {empresas.map((empresa) => (
          <option key={empresa.ID}>{empresa.Nombre}</option>
        ))}
      </select>

      {/* <SelectCultivos /> */}
      {/* <SelectsAnidados /> */}
      {/* <button className="btn btn-primary">Buscar</button> */}
      <GraficoBarras coins={coins} search={search} />
      <GraficoCircular coins={coins} search={search} />
    </div>
  );
}

export default App;
