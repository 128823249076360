import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { servicio } from "../data/Data";
import DarkUnica from "highcharts/themes/dark-unica";
DarkUnica(Highcharts);

const GraficoCircular = ({ coins, search }) => {
  const titleStyle = { textAlign: "center" };
  let title;

  // const options = {
  //   title: {
  //     text: "My chart",
  //   },
  //   series: [
  //     {
  //       data: [100, 200, 300, 50, 70],
  //     },
  //   ],
  // };

  let arregloEmpresaCantidad = [];
  let total = 0;
  let subtotal = 0;
  let servicio;

  if (search === "" || search === "Todas") {
    title = "Total de accesos por empresa";
    servicio = coins;
    servicio.forEach((element) =>
      element.Fundos.forEach((element2) =>
        element2.Ingresos.forEach(
          (element3) => (total = total + element3.Cantidad)
        )
      )
    );
    for (let i = 0; i < servicio.length; i++) {
      const emp = servicio[i].Empresa;
      const fund = servicio[i].Fundos;
      subtotal = 0;
      for (let i = 0; i < fund.length; i++) {
        const ing = fund[i].Ingresos;
        for (let i = 0; i < ing.length; i++) {
          subtotal = subtotal + ing[i].Cantidad;
        }
      }
      // arregloEmpresaCantidad.push({ Empresa: emp, Cantidad: subtotal });
      arregloEmpresaCantidad.push({
        name: `${emp} (${subtotal})`,
        y: subtotal / total,
      });
    }
  } else {
    title = `Total de accesos por fundos de ${search}`;
    servicio = coins.filter((e) => e.Empresa === search);
    servicio.forEach((element) =>
      element.Fundos.forEach((element2) =>
        element2.Ingresos.forEach(
          (element3) => (total = total + element3.Cantidad)
        )
      )
    );
    for (let i = 0; i < servicio.length; i++) {
      // const emp = servicio[i].Empresa;
      const fund = servicio[i].Fundos;
      for (let i = 0; i < fund.length; i++) {
        const ing = fund[i].Ingresos;
        subtotal = 0;
        for (let i = 0; i < ing.length; i++) {
          subtotal = subtotal + ing[i].Cantidad;
        }
        arregloEmpresaCantidad.push({
          name: `${fund[i].Fundo} (${subtotal})`,
          y: subtotal / total,
        });
      }
      // arregloEmpresaCantidad.push({ Empresa: emp, Cantidad: subtotal });
    }
  }

  // const datos = [
  //   {
  //     name: "ACP",
  //     y: 10.45,
  //   },
  //   {
  //     name: "Agrovision",
  //     y: 19.55,
  //   },
  //   {
  //     name: "Safco",
  //     y: 19.65,
  //     // sliced: true,
  //     // selected: true,
  //   },
  //   {
  //     name: "Pedregal",
  //     y: 20.35,
  //   },
  //   {
  //     name: "Pampa baja",
  //     y: 10.65,
  //     // sliced: true,
  //     // selected: true,
  //   },
  //   {
  //     name: "Agricola Chapi",
  //     y: 9.3,
  //   },
  //   {
  //     name: "Manuelita",
  //     y: 10.05,
  //   },
  // ];

  // let arregloEmpresaCantidad = [
  //   { Empresa: "Uno", Cantidad: 1 },
  //   { Empresa: "Dos", Cantidad: 2 },
  //   { Empresa: "Tres", Cantidad: 3 },
  // ];

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "pie",
      // plotBackgroundColor: "#f8f9fa", // Color de fondo del gráfico
      plotBorderWidth: 2,
      // plotBorderwidth: 5,
      plotShadow: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}:<b>{point.percentage:.2f}</b>%",
      // pointFormat: "{series.name}:<b>{point.percentage:.5f}</b>%",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        curso: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}:<b>{point.percentage:.3f}</b>%",
          // format: "{point.name}:<b>{point.percentage:.5f}</b>%",
        },
      },
    },
    series: [
      {
        name: "Porcentaje",
        colorByPoint: true,
        data: arregloEmpresaCantidad,
      },
    ],
  };

  return (
    <div id="GraficoCircular">
      <h3 style={titleStyle}>{title}</h3>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default GraficoCircular;
